<template>
  <div class="container">
    <van-nav-bar
      title="代办员认证"
      :fixed="true"
      left-arrow
      @click-left="goBack"
      @click-right="goHome"
    >
      <template #right>
        <van-icon
          class="iconfont"
          class-prefix="icon"
          name="index-o"
          size="16"
        />
      </template>
    </van-nav-bar>
    <!-- 用户信息 -->
    <van-cell-group style="margin-top: 48px">
      <van-field
        input-align="right"
        label-class="label-class"
        label=" 姓名"
        :required="true"
        :readonly="userStatus == 2 || userStatus == 3"
        v-model="name"
        placeholder="请输入姓名"
      />
      <van-field
        input-align="right"
        label-class="label-class"
        label=" 手机号码"
        :required="true"
        type="number"
        :readonly="userStatus == 2 || userStatus == 3"
        v-model="phone"
        placeholder="请输入手机号码"
        use-button-slot
      >
        <van-button
          v-if="userStatus != 2 && userStatus != 3"
          slot="button"
          size="small"
          type="primary"
          plain
          color="#F3C301"
          @click="sendSMS"
          >发送验证码</van-button
        >
      </van-field>
      <van-field
        v-if="userStatus != 2 && userStatus != 3"
        input-align="right"
        label-class="label-class"
        label="验证码"
        v-model="phoneCode"
        placeholder="请输入验证码"
      ></van-field>
      <van-field
        input-align="right"
        label-class="label-class"
        v-model="cardNo"
        :readonly="userStatus == 2 || userStatus == 3"
        label=" 身份证号"
        :required="true"
        placeholder="请输入身份证号"
      />
      <van-cell
        label-class="labelClass"
        title="身份证正面"
        label="请上传身份证正面照"
      >
        <van-uploader
          :after-read="afterReadA"
          v-model="fileA"
          multiple
          :deletable="userStatus != 2 && userStatus != 3"
          :max-count="1"
        />
      </van-cell>
      <van-cell
        label-class="labelClass"
        title="身份证反面"
        label="请上传身份证反面照"
      >
        <van-uploader
          :after-read="afterReadB"
          v-model="fileB"
          :deletable="userStatus != 2 && userStatus != 3"
          multiple
          :max-count="1"
        />
      </van-cell>
      <van-cell :border="false" center>
        <template #title>
          <div class="g-title-box">
            <div class="title">代办业务类型</div>
            <div class="des">(平台将按照您选择的类型派单)</div>
          </div>
        </template>
      </van-cell>
      <div class="content-box">
        <van-checkbox-group
          class="service-box"
          @change="serviceChange"
          v-model="selectedServiceList"
          v-if="userStatus != 2"
        >
          <van-checkbox
            v-for="item in serviceTypeList"
            :key="item.index"
            class="service-item"
            shape="square"
            :disabled="false"
            icon-size="16px"
            checked-color="#f3c81b"
            :name="item.id"
            >{{ item.zh_name }}</van-checkbox
          >
        </van-checkbox-group>
        <van-checkbox-group
          class="service-box"
          v-model="selectedServiceList"
          :change="serviceChange"
          v-else
        >
          <van-checkbox
            v-for="item in serviceTypeList"
            :key="item.index"
            class="service-item"
            shape="square"
            :disabled="true"
            icon-size="18px"
            :name="item.id"
            >{{ item.zh_name }}</van-checkbox
          >
        </van-checkbox-group>
        <!--
          <div
            class="service-item"
            v-for="item in serviceTypeList"
            :key="item.index"
          >
            <van-checkbox v-model="checked">复选框</van-checkbox>
            <van-button
              size="small"
              round
              block
              v-if="
                userStatus == 1 ||
                  (userStatus == 4 && isContains(item.id, selectedServiceList))
              "
              :color="isContains(item.id, selectedServiceList) ? '#f3c81b' : ''"
              @click="switchService(item.id)"
            >
              <span>{{ item.zh_name }}</span></van-button
            >
            <van-button
              size="small"
              round
              block
              v-else
              :color="isContains(item.id, selectedServiceList) ? '#f3c81b' : ''"
            >
              <span>{{ item.zh_name }}</span></van-button
            >
          </div>
          -->
      </div>

      <van-cell
        :border="false"
        center
        is-link
        v-if="userStatus != 2"
        @click="showAreaPopup"
      >
        <template #title>
          <div class="g-title-box">
            <div class="title">代办业务范围</div>
            <div class="des">(设置之后只能推送范围内订单)</div>
          </div>
        </template>
        <template #right-icon>
          <van-icon name="plus" />
        </template>
      </van-cell>
      <van-cell :border="false" center is-link v-else>
        <template #title>
          <div class="g-title-box">
            <div class="title">代办业务范围</div>
            <div class="des">(设置之后只能推送范围内订单)</div>
          </div>
        </template>
      </van-cell>
      <van-cell v-if="addressList.length" :border="false">
        <template #title>
          <div class="p-cell">
            <div v-for="(item, index) in addressList" :key="index">
              <van-tag
                color="#f3c302"
                class="space"
                plain
                v-if="userStatus != 2"
                round
                closeable
                size="medium"
                type="primary"
                @close="removeArea(item.value)"
                :item="item"
              >
                {{ item.value }}
              </van-tag>
              <van-tag
                color="#f3c302"
                class="space"
                plain
                v-else
                round
                :closeable="false"
                size="medium"
                type="primary"
                :item="item"
              >
                {{ item.value }}
              </van-tag>
            </div>
          </div>
        </template>
      </van-cell>

      <van-cell :border="false" center>
        <template #title>
          <div class="g-title-box">
            <div class="title">证件证明</div>
            <div class="des">(请上传名片和技能证书)</div>
          </div>
        </template>
      </van-cell>
      <van-cell>
        <van-uploader
          :after-read="afterReadC"
          :before-delete="beforeDeleteC"
          v-model="fileC"
          multiple
          :deletable="userStatus != 2"
          :show-upload="userStatus != 2"
        />
      </van-cell>
    </van-cell-group>
    <van-cell :border="false" center v-if="userStatus!=2">
      <template #title>
        <div class="g-title-box">
          <div class="title">个人名片</div>
          <div class="des"></div>
        </div>
      </template>
    </van-cell>
  
    <!--个人名片-->
    <div v-if="userStatus!=2" id="person-card" ref="imageTofile">
      <div class="avator-info">
        <div class="name">{{ name }}</div>
        <div class="grade">{{ grade }}代办</div>
      </div>
      <div class="phone">{{ phone }}</div>
      <div class="service">
        <span
          class="spaceR"
          v-for="(item, index) in serviceTypeNameList"
          :key="index"
        >
          {{ item }}
        </span>
      </div>
      <div class="area">
        <span class="spaceR" v-for="(item, index) in addressList" :key="index">
          {{ item.value }}
        </span>
      </div>
      <div class="address">{{ personAddress }}</div>
    </div>

    <div class="button-box">
      <van-button
        v-if="userStatus == 1"
        round
        block
        type="info"
        color="#F3C301"
        @click="authentication"
      >
        <span>发起认证</span></van-button
      >
      <van-button
        v-else-if="userStatus == 2"
        round
        block
        type="info"
        color="#F3C301"
      >
        <span>认证中</span></van-button
      >
      <van-button
        @click="authentication"
        v-else
        round
        block
        type="info"
        color="#F3C301"
      >
        <span>已认证(重新认证)</span></van-button
      >
    </div>
    <div class="kbox"></div>
    <!-- 区域选择弹出框--->
    <van-popup v-model="showArea" position="bottom">
      <van-area
        title="选择业务范围"
        :area-list="areaList"
        visible-item-count="4"
        :value="code"
        @confirm="ensureArea"
        @cancel="closeAreaPopup"
      />
    </van-popup>
  </div>
</template>

<script>
import AreaList from "@/utils/area";
import ServiceCateService from "@/api/service";
import UserService from "@/api/user";
import BaseService from "@/api/base";
import { Toast } from "vant";
import html2canvas from "html2canvas";
export default {
  data() {
    return {
      userStatus: 1, //未认证
      selectedServiceList: [],
      serviceOptions: [], //选择的详细服务
      showArea: false,
      showPArea: false,
      showCArea: false,
      personAddress: "",
      pArea: null, //代办员区域
      areaList: AreaList,
      staff_card: "",
      code: "500112",
      addressList: [], // 区域
      fileA: [], //身份证图片
      fileB: [], //身份证图片
      fileC: [],
      fileD: [],
      fileAPath: "",
      fileBPath: "",
      fileCPath: "",
      fileDPath: "",
      serviceTypeList: null, //入驻类型列表
      serviceList: null, //车辆服务列表
      name: "", //代办员姓名
      cardNo: "", //身份证号
      phone: "", //代办员手机号
      phoneCode: "", //手机验证码
      company: "", //公司名称
      companyArea: "", //公司区域
      companyPhone: "", //公司电话
      pushPersonName: "", //推荐人姓名
      grade: "",
      serviceTypeNameList: [],
      htmlUrl: "",
      isShow: false,
    };
  },
  async beforeMount() {
    this.initData();
  },
  created() {
    //var that = this;
    this.$nextTick(function() {
      //that.generatePersonCard()
    });
  },

  methods: {
    /*
    generatePersonCard(){
      var mycanvas = document.getElementById('person-card')
			var ctx = mycanvas.getContext('2d')

			// 内存中先加载，然后当内存加载完毕时，再把内存中的数据填充到我们的 dom元素中，这样能够快速的去
			// 反应，比如网易的图片
			var img = new Image();
      img.src = "https://jsagency.cn/static/upload/20210826/0b9219867168f7a0b0d9186b49e04561.jpeg";

			img.onload = function(){
				alert('加载完毕')
				// 将图片画到canvas上面上去！
				ctx.drawImage(img,0,0,600,600);

			}
    },
    */
    toImage() {
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      var that = this;
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        that.htmlUrl = url; // 把生成的base64位图片上传到服务器,生成在线图片地址
        if (that.htmlUrl) {
          // 渲染完成之后让图片显示，用v-show可以避免一些bug
          that.isShow = true;
        }
        // this.sendUrl();
      });
    },

    async initData() {
      const resp = await ServiceCateService.getServiceCate(this, {});
      if (resp.data.code == 10000) {
        var listR = resp.data.data;
        this.serviceTypeList = listR.slice(1, listR.length);
      }
      const resp1 = await ServiceCateService.getCarServiceList(this, {});
      if (resp1.data.code == 10000) {
        this.serviceList = resp1.data.data;
      }

      //得到用户统计信息
      var token = this.$cookie.get("token");
      //var token = "609cd3e4fb73f188ec2f9f84aecdf511-2";
      var params = {
        token: token,
      };
      const resp2 = await UserService.staffInfo(this, params);
      if (resp2.data.code == 10000) {
        this.userStatus = resp2.data.data.order_status;
        var options = resp2.data.data.order_type;
        if (options) {
          var list = options.split("|");
          var arr = this.selectedServiceList;
          list.forEach(function(item) {
            if (item != "") {
              arr.push(parseInt(item));
            }
          });
          this.selectedServiceList = arr; //选择的详细服务
          console.log(arr);
        }
        this.pArea = resp2.data.data.staff_add; //代办员区
        var address = resp2.data.data.order_round;
        if (address) {
          var list1 = address.split("|");
          this.addressList = [];
          var arr1 = this.addressList;
          list1.forEach(function(item) {
            if (item != "") {
              arr1.push({ value: item });
            }
          });
          this.addressList = arr1; //服务范围
        }
        var fileA = new Array();
        if (
          resp2.data.data.staff_cardImga != null &&
          resp2.data.data.staff_cardImga != ""
        ) {
          fileA.push({
            url: "https://jsagency.cn/" + resp2.data.data.staff_cardImga,
          });
        }
        this.fileA = fileA;
        console.log(fileA);
        console.log(this.fileA);

        var fileB = new Array();
        if (
          resp2.data.data.staff_cardImga != null &&
          resp2.data.data.staff_cardImgb != ""
        ) {
          fileB.push({
            url: "https://jsagency.cn/" + resp2.data.data.staff_cardImgb,
          });
        }
        this.fileB = fileB;

        var fileCPath = resp2.data.data.staff_wordimgb;
        console.log(fileCPath);
        if (fileCPath != "" && fileCPath != null) {
          //fileC = fileCPath.split("|");
          this.fileCPath = fileCPath.substring(0, fileCPath.length);
        }
        var fileCList = fileCPath.split("|");
        var fileCNlist = new Array();
        fileCList.forEach(function(item) {
          if (item != "") {
            fileCNlist.push({
              url: item,
            });
          }
        });
        this.fileC = fileCNlist;
        this.fileAPath = resp2.data.data.staff_cardImga;
        this.fileBPath = resp2.data.data.staff_cardImgb;
        this.name = resp2.data.data.staff_name;
        this.cardNo = resp2.data.data.staff_cardNum;
        this.phone = resp2.data.data.staff_phone;
        this.company = resp2.data.data.staff_office_name;
        this.companyArea = resp2.data.data.staff_office_address;
        this.companyPhone = resp2.data.data.staff_office_phone;
        this.pushPersonName = resp2.data.data.staff_referee;
        this.grade = resp2.data.data.grade_name;
        this.personAddress = resp2.data.data.staff_address;
        var lists = resp2.data.data.order_type_use;
        this.staff_card = 'https://jsagency.cn'+resp2.data.data.staff_card;
        var listRR = lists.split("|");
        var lista = this.serviceTypeList;
        var serviceTypeNameList = new Array();
        lista.forEach(function(item) {
          listRR.forEach(function(e) {
            if (item.id == e) {
              serviceTypeNameList.push(item.zh_name);
            }
          });
        });

        this.serviceTypeNameList = serviceTypeNameList;
        console.log(this.serviceTypeNameList);
      }
    },
    serviceChange(e) {
      var listRR = e;
      var lista = this.serviceTypeList;
      var serviceTypeNameList = new Array();
      lista.forEach(function(item) {
        listRR.forEach(function(e) {
          if (item.id == e) {
            serviceTypeNameList.push(item.zh_name);
          }
        });
      });

      this.serviceTypeNameList = serviceTypeNameList;
      console.log(this.serviceTypeNameList);
    },
    isContains(item, list) {
      list.forEach(function(e) {
        if (item == e) {
          return true;
        }
      });
      return false;
    },
    async sendSMS() {
      var params = {
        type: 2,
        phone: this.phone,
      };
      const resp = await UserService.sendCode(this, params);
      console.log(resp);
    },
    //认证
    async authentication() {
      var serviceList = this.selectedServiceList;
      console.log(this.serviceOptions);
      var addressList = this.addressList;
      var order_service = "";
      var code = "";
      if (this.userStatus == 3) {
        code = "0000";
      } else {
        code = this.phoneCode;
      }
      if (serviceList.length < 1) {
        //return false;
      }
      serviceList.forEach(function(item) {
        order_service = order_service + item + "|";
      });
      if (code == "") {
        Toast("验证码不能为空");
      }

      var order_round = "";
      if (addressList.length < 1) {
        //return false;
      }
      addressList.forEach(function(item) {
        order_round = order_round + item.value + "|";
      });
      if (this.fileC.length > 0) {
        var filePath = this.fileCPath;
        this.fileCPath = filePath.substring(0, filePath.length);
      }
      console.log(this.fileCPath);
      console.log(this.htmlUrl);

      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      var that = this;
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/png");
        that.htmlUrl = url; // 把生成的base64位图片上传到服务器,生成在线图片地址
        const params = {
          token: this.$cookie.get("token"),
          //token: "609cd3e4fb73f188ec2f9f84aecdf511-2",
          data: {
            code: code, //手机验证码
            order_type: order_service, //入驻类型,估计以后可以多选
            order_round: order_round, //业务范围
            staff_name: this.name, //姓名
            staff_form: 1, //来源1公众号，2小程序
            staff_cardNum: this.cardNo, //身份证号
            staff_cardImga: this.fileAPath, //正面
            staff_cardImgb: this.fileBPath, //反面
            staff_wordimgb: this.fileCPath, //证件照
            staff_card: this.htmlUrl,
            staff_phone: this.phone, //个人电话
            staff_add: this.pArea, // 个人地址
            staff_office_name: this.company,
            staff_office_phone: this.companyPhone,
            staff_office_address: this.companyArea,
            //staff_referee: this.pushPersonName,
          },
        };
        UserService.todoStaffCert(this, params).then((result) => {
          if (result.data.code == 10000) {
            //this.$router.push("/");
            this.initData();
          } else {
            Toast(result.data.msg);
          }
        });
      });
    },
    goHome: function() {
      this.$router.push("/");
    },
    goBack: function() {
      this.$router.go(-1);
    },
    showAreaPopup: function() {
      this.showArea = true;
    },
    closeAreaPopup: function() {
      this.showArea = false;
    },

    removeArea: function(value) {
      var list = this.addressList.filter((item) => item.value != value);
      this.addressList = list;
    },
    ensureArea: function(val) {
      var data = val;
      var area = "";
      data.forEach((e) => {
        area = area + e.name;
      });
      area = data[0].name + "-" + data[1].name + "-" + data[2].name;
      var flag = false;
      this.addressList.forEach(function(el) {
        if (el.value == area) {
          flag = true;
        }
      });
      if (!flag) {
        this.addressList.push({ value: area });
      }
      console.log(this.addressList);
      this.closeAreaPopup();
    },
    showPAreaPopup: function() {
      this.showPArea = true;
    },
    closePAreaPopup: function() {
      this.showPArea = false;
    },
    ensurePArea: function(val) {
      var data = val;
      var area = "";
      data.forEach((e) => {
        area = area + e.name;
      });
      this.pArea = area;

      this.closePAreaPopup();
    },

    showCAreaPopup: function() {
      this.showCArea = true;
    },
    closeCAreaPopup: function() {
      this.showCArea = false;
    },
    ensureCArea: function(val) {
      var data = val;
      var area = "";
      data.forEach((e) => {
        area = area + e.name;
      });
      this.companyArea = area;

      this.closeCAreaPopup();
    },
    async switchService(type) {
      var selectedList = this.selectedServiceList;
      if (this.isContains(type, selectedList)) {
        //删除元素
      } else {
        selectedList.push(type);
      }
      this.selectedServiceList = selectedList;
      console.log(this.selectedServiceList);
    },
    async afterReadA(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        this.fileAPath = resp.data.data.imgUrl;
        file.status = "done";
      }
    },
    async afterReadB(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        this.fileBPath = resp.data.data.imgUrl;
        file.status = "done";
      }
    },
    async afterReadC(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("image", file.file);
      const resp = await BaseService.uploadImg(this, formData);
      if (resp.data.code == 10000) {
        this.fileCPath =
          this.fileCPath + "|" + "https://jsagency.cn/" + resp.data.data.imgUrl;
        file.status = "done";
      }
    },

    beforeDeleteC: function(event) {
      console.log(event);
      var fileCList = this.fileC;
      var fileCNewList = new Array();
      fileCList.forEach(function(item) {
        if (item.url != event.url) {
          fileCNewList.push(item);
        }
      });
      this.fileC = fileCNewList;
      var fileCPathNew = "";
      fileCNewList.forEach(function(item) {
        fileCPathNew = fileCPathNew + item.url + "|";
      });
      this.fileCPath = fileCPathNew.substring(0, fileCPathNew.length - 1);
      console.log(this.fileC);
      console.log(this.fileCPath);
    },
  },
};
</script>

<style>
#person-card {
  background-size: 100% 100%;
  background: url("~@/assets/img/card-bg.jpeg") no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  margin: 10px 10px;
  height: 600px;
  position: relative;
}
#person-card .avator-info {
  margin-top: 200px;
  text-align: center;
  position: absolute;
  width: 100%;
}
.avator-info .name {
  font-size: 28px;
  font-weight: bold;
  color: #14527f;
}
.avator-info .grade {
  font-size: 20px;
  font-weight: bold;
  color: #14527f;
}
#person-card .phone {
  margin-top: 326px;
  margin-left: 80px;
  position: absolute;
  font-size: 18px;
  color: #000;
}

#person-card .service {
  margin-top: 360px;
  margin-left: 80px;
  margin-right: 20px;
  position: absolute;
  font-size: 14px;
  color: #000;
}

#person-card .area {
  margin-top: 410px;
  margin-left: 80px;
  position: absolute;
  margin-right: 20px;
  font-size: 14px;
  color: #000;
}

#person-card .address {
  margin-top: 450px;
  margin-right: 20px;
  margin-left: 80px;
  position: absolute;
  font-size: 14px;
  color: #000;
}
.van-checkbox__icon--disabled.van-checkbox__icon--checked .van-icon {
  color: #f3c301 !important;
}

.van-checkbox__icon--disabled .van-icon {
  background-color: #fff !important;
  border-color: #f3c301 !important;
}
.labelClass {
  width: 150px;
}
.optional {
  background: #fafafa;
  font-size: 14px;
  color: #333;
  padding: 8px 16px;
}
.label-class {
  width: 60px !important;
  text-align-last: justify !important;
}
.container {
  background-color: #ffffff;
}
.van-cell-group__title {
  color: #000 !important;
  font-size: 14px !important;
}
.service-box {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.service-box1 {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.service-box .service-item {
  width: 30%;
  margin: 10px 0px;
}

.service-box1 .service-item1 {
  min-width: 80px;
  margin: 10px 0px;
  padding: 0 5px;
  text-align-last: justify;
}
.service-box1 .service-item1 span {
  color: #333;
}

.service-box .service-item span {
  color: #333;
}
.space {
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.p-cell {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.button-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px 40px 10px 40px;
}
.g-title-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.g-title-box .des {
  font-size: 10px;
  color: #ffa44d;
  margin-left: 6px;
  font-weight: 700;
}
.g-title-box .title {
  min-width: 30px;
}
.content-box {
  margin-left: 18px;
  margin-right: 18px;
}
</style>
